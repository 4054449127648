import React,{useState,useEffect} from "react";
import Layout from "../components/layout";
import { useStaticQuery,graphql} from "gatsby";
import {GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import ImageProject from "images/icon/arrow-right.svg";
import "../styles/page/projetc.css";
import BlockIcon from "../components/homepage/block-icon";
import icondate from "../images/calendar-alt.svg";
const project = () => {
  const [cards,setCards] = useState([]);
  const {strapiProjectPage,allStrapiProjectCategory,allStrapiProject } = useStaticQuery(graphql`
    query {
      allStrapiProjectCategory(
        sort: { fields: [createdAt], order: DESC }
      ){
        nodes {
          title
          slug
          description
          id
          projects {
            title
            slug
            id
            description
            cover {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80
                    webpOptions: {quality: 80}
                    placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      strapiProjectPage {
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80
                webpOptions: {quality: 80}
                placeholder: BLURRED)
            }
          }
        }
      }
      allStrapiProject(
        sort: { fields: [createdAt], order: DESC }
      ){
        nodes {
          title
          slug
          id
          description
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80
                  webpOptions: {quality: 80}
                  placeholder: BLURRED)
              }
            }
          }
          
        }
      }
    }
  `);
  let  [number,SetNumber] = useState()
  const [bntaddclass,setBntaddclass] = useState(false)
  const [scards,setCardss] = useState([]);
  const [switchToggled , setSwitchToggled]  = useState(false)

  const filterClick = (category, index) => {
    let results = allStrapiProjectCategory.nodes.filter(
      (item) => item.title  === category 
    );
    SetNumber(index)
    setCards(results)
    setCardss(category)
    setBntaddclass(true)
  } 
  const projectload = scards
  const showAll = () => {
    setCards(allStrapiProject.nodes);
    setCardss(false)
    setSwitchToggled(false)
    setBntaddclass(false)
    SetNumber(-1)
  };
  useEffect(() => {
    setCards(allStrapiProjectCategory.nodes);
  }, [allStrapiProjectCategory]);
  return (
    <Layout>
      <GatsbySeo 
        title="Dự án"
      />
      <div className="about">
          <div >
             <GatsbyImage image={strapiProjectPage.image.localFile.childImageSharp.gatsbyImageData} alt={strapiProjectPage.image.alternativeText} className="project__baner"/>
            <div className="container">
              <div className="baner__slider Home__siteTitle">
                <div>
                  <Link title="Trang-chu" to="/" className="baner__title">
                    Trang Chủ
                  </Link>{" "}
                  <span className="baner__title">/ {strapiProjectPage.title}</span>
                </div>
                <h2 className="baner__desc">{strapiProjectPage.title}</h2>
              </div>
            </div>
          </div>
      </div>
      <div className="project">
          <div className="projetc home__siteTitle">
            <h2>dự án đang mở bán tại alphaland group</h2>
          </div>
          <div className="about__partner">
                  <button  onClick={showAll}  className={switchToggled ? "button__name" : "project__butoncl" && bntaddclass ? "button__name" : "project__butoncl"}>Tất cả</button>
                {allStrapiProjectCategory.nodes.map((itemproject, index) => (
                  <button className={number === index ? "project__butoncl" : "button__name"}  key={index} onClick={() => filterClick(itemproject.title, index)}> {itemproject.title} </button>
                ))}
                {projectload == false ? (
                   <div className="container">
                   <div className="projectNew">
                     {allStrapiProject.nodes.map((project,key) => (
                       <Link
                       key={key}
                       id={key}
                       title={project.title}
                       to={`/du-an/${project.slug}`}
                       className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
                     >
                       <div className="projectNew__row ">
                         <div className="projectNew__image hover14 column">
                           <figure>
                             <GatsbyImage image={project.cover?.localFile?.childImageSharp?.gatsbyImageData} alt={project.cover.alternativeText} className="project__Img"/>
                           </figure>
                         </div>
                         <div className="projectNew__desc">
                           <h3>{project.title}</h3>
                           <p>{project.description}</p>
                           <button  className="button">
                             chi tiết
                             <img src={ImageProject} className="icon_button" />
                           </button>
                         </div>
                       </div>
                       </Link>
                     ))}
                   </div>
                 </div>
                ) : (
                  <>
                    {cards.map((item1,id)=>(
                  <div key={id} className="container">
                          <div className="projectNew__category">
                                    <>
                                    {item1.projects?.map((project,key) => (
                                        <Link
                                        key={key}
                                        title={project.title}
                                        to={`/du-an/${project.slug}`}
                                        className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
                                      >
                                        <div className="projectNew__row ">
                                          <div className="projectNew__image hover14 column">
                                            <figure>
                                            <GatsbyImage image={project.cover?.localFile?.childImageSharp?.gatsbyImageData} alt={project.cover.alternativeText} className="project__Img"/>
                                            </figure>
                                          </div>
                                          <div className="projectNew__desc">
                                            <h3>{project.title}</h3>
                                            <p>{project.description}</p>
                                            <button  className="button">
                                              chi tiết
                                              <img src={ImageProject} className="icon_button" />
                                            </button>
                                          </div>
                                        </div>
                                        </Link>
                                    ))}
                                    </>
                          </div>
                  </div>
                ))}
                  </>
                )}
          </div>
      </div>
      <BlockIcon/>
    </Layout>
  );
};

export default project;
